$primary:       #094151;
$control-grey: #DFE6E8;
// Device sizes
$mobile: 360px;
$desktop: 1366px;
$tablet: 768px;
$large: 1366px;

$theme-colors: (
    "primary": $primary,
    "control-grey": $control-grey,
    "warning": #ffc107,
    "light-grey": #f6f6f6
);

$border-radius: 6px;

.main-box{
    .LeapFrog-module_container__XNG48{
        display: block;
        margin: auto;
        z-index: 1;
        top: 250px;
    }
}

.app{
    @media (min-width: $tablet) {
    }
    
    @media (max-width: $tablet) {
    }

}
.center-loader{
    width: 100vw;
    height: 100vh;
}
