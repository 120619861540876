.main-box .LeapFrog-module_container__XNG48 {
  z-index: 1;
  margin: auto;
  display: block;
  top: 250px;
}

.center-loader {
  width: 100vw;
  height: 100vh;
}

/*# sourceMappingURL=index.2345f962.css.map */
